/* CalendarView.css */
.fc {
    width: 100%; /* 设置日历宽度为 100% */
    height: 80vh; /* 设置日历高度为视口高度的 80% */
    font-size: 16px; /* 调整字体大小 */
}

/* 移除 all-day 行的空白列 */
.fc-timegrid .fc-timegrid-slots table {
    width: 100%;
}

/* 工具栏样式 */
.fc-header-toolbar {
    margin-bottom: 1em; /* 调整工具栏的底部间距 */
}

.fc-toolbar-title {
    font-size: 1.5em; /* 调整标题字体大小 */
}

/* 事件样式 */
.fc-event {
    font-size: 0.8rem !important; /* 调小事件文字 */
    padding: 2px 4px !important; /* 调整事件内边距 */
    min-height: 38px !important; /* 事件高度适应新行高 */
    margin: 2px 0 !important;
}

.equipment-title {
    margin-bottom: 1em; /* 调整设备标题的底部间距 */
}

.calendar-week-container {
    overflow-x: auto; /* 允许横向滚动 */
    min-width: 1200px; /* 最小宽度保证内容展开 */
}

.calendar-week-container::-webkit-scrollbar {
    height: 8px;
}

.fc-timegrid-slots td {
    min-width: 120px; /* 时间列最小宽度 */
    height: 40px !important; /* 调大时间格高度 */
}

.fc-timegrid-axis {
    font-size: 0.8rem !important; /* 时间轴文字 */
    padding-top: 12px !important; /* 时间标签垂直间距 */
}

.fc-col-header-cell {
    font-size: 0.8rem !important; /* 表头文字 */
}

/* 响应式调整 */
@media (max-width: 768px) {
    .calendar-week-container {
        min-width: 1000px;
    }
    .fc-timegrid-slots td {
        min-width: 100px;
    }
}

.fc-timegrid-event-harness {
    transform: none !important; /* 禁用垂直位置压缩 */
}

.fc-scrollgrid-section-body td {
    height: 40px !important; /* 同步调整内容区域高度 */
}

.fc-event-title {
    font-size: 0.9rem !important;
    line-height: 1.3 !important;
}

.fc-timegrid-slots {
    min-height: 150vh; /* 根据需求调整最小高度 */
}

.fc-timegrid-slot {
    height: 50px !important; /* 调大时间格高度 */
}

.fc-scroller {
    overflow-y: auto !important; /* 启用垂直滚动 */
}

